import { createReducer, on } from '@ngrx/store';
import * as EventsPageActions from '../actions/events-page.actions';
import { EventState } from './event.reducer';

export const initialState: EventState[] = [];

export const eventsReducer = createReducer(
  initialState,
  on(EventsPageActions.upsertEvent, (state, payload: EventState) => {
    const find = state.find((event) => event.id === payload.id);

    if (!find) {
      return [...state, payload];
    }

    return state.map((event) => (payload.id === event.id ? payload : event));
  }),
);
