import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';

import * as fromLogin from '../reducers/login.reducer';
import * as fromEvent from '../reducers/event.reducer';
import * as fromEvents from '../reducers/events.reducer';

import { localStorageSync } from 'ngrx-store-localstorage';

// console.log all actions
export const debug = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state, action) => {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
};

export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return localStorageSync({ keys: ['user', 'event', 'events'], rehydrate: true })(reducer);
};

export interface AppState {
  user;
  event;
  events;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromLogin.loginReducer,
  event: fromEvent.eventReducer,
  events: fromEvents.eventsReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [debug, localStorageSyncReducer]
  : [localStorageSyncReducer];
