import { createReducer, on } from '@ngrx/store';
import * as EventPageActions from '../actions/event-page.actions';

interface Professional {
  id: number;
  name: string;
  observations: string;
  pains: [];
}

export interface Occupation {
  id: number;
  professionals: Professional[];
  color?: string;
  quantity?: number;
  body_maps?: any[];
  work_maps?: any[];
  problems?: any[];
  our_world_maps?: any[];
  description?: string;
  name?: string;
}

export interface EventState {
  id: number;
  title: string;
  quantity: number;
  quantity_end: number;
  date: string;
  occupations?: Occupation[];
  groups?: any[];
  our_world_map_description?: string;
  our_world_map_photos?: any[];
}

export const initialState: EventState = {
  id: null,
  title: '',
  quantity: null,
  quantity_end: null,
  date: null,
  occupations: [],
  groups: [],
};

export const eventReducer = createReducer(
  initialState,
  on(EventPageActions.setEvent, (state, payload: EventState) => payload),
);
