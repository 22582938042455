import { HttpErrorResponse } from '@angular/common/http';

export const handleError = (err: HttpErrorResponse): any => {
  // eslint-disable-next-line prefer-const
  let { fields, message = 'Erro ao realizar requisição. Tente novamente!' } =
    err?.error?.error || err?.error;

  if (!fields) {
    fields = err.error.errors;
  }

  return { fields, message };
};
