import { createReducer, on } from '@ngrx/store';
import * as LoginPageActions from '../actions/login-page.actions';

export interface LoginState {
  name: string;
  token: string;
  email: string;
}

export const initialState: LoginState = {
  name: '',
  token: '',
  email: '',
};

export const loginReducer = createReducer(
  initialState,
  on(LoginPageActions.setUser, (state, { name, token, email }) => ({ name, token, email })),
  on(LoginPageActions.updateToken, (state, { token }) => ({ ...state, token })),
  on(LoginPageActions.removeUser, (state) => ({ name: '', token: '', email: state.email })),
);
