import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private navController: NavController) {}

  isSigned(): Promise<any> | boolean {
    const user = localStorage.getItem('user');

    if (user) {
      const { token } = JSON.parse(user);

      if (!token) {
        this.navController.navigateRoot('/');
        return false;
      }

      return true;
    }

    this.navController.navigateRoot('/');
    return false;
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isSigned();
  }

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isSigned();
  }
}
