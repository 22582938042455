import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate, CanActivateChild {
  constructor(private navController: NavController) {}

  async redirect(): Promise<any> {
    const user = localStorage.getItem('user');

    if (user) {
      const { token } = JSON.parse(user);

      if (token) {
        this.navController.navigateRoot('/dashboard');
        return false;
      }
    }

    return true;
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.redirect();
  }

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.redirect();
  }
}
