import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../../../../shared/services/base/base.service';
import { AuthTokenItemRead } from './models';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService extends BaseService<AuthTokenItemRead, null, null> {
  constructor(private readonly httpClient: HttpClient) {
    super(httpClient, 'auth/refresh', 'v1');
  }
}
