import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { handleError } from '../../../shared/utils/error';
import { AuthTokenService } from '../../service/auth/auth-token/auth-token.service';
import { AppState } from '../../../store/reducers';
import { removeUser, updateToken } from '../../../store/actions/login-page.actions';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  refreshing = false;

  constructor(
    private navController: NavController,
    private modalController: ModalController,
    private store: Store<AppState>,
    private auth: AuthTokenService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        const { refreshing } = this;
        const { status } = err;

        if (status === 401 && !refreshing) {
          this.refreshing = true;
          this.auth.create(null).subscribe(
            async (res) => {
              const { token } = res;
              this.store.dispatch(updateToken({ token }));

              await this.navController.navigateRoot('/dashboard', {
                queryParams: { refresh: true },
              });

              this.closeAllModals();

              this.refreshing = false;
            },
            async () => this.clear(),
          );
        }

        return throwError(handleError(err));
      }),
    );
  }

  async clear() {
    this.store.dispatch(removeUser());
    await this.navController.navigateRoot('/entrar', { queryParams: { refresh: true } });
    this.refreshing = false;
  }

  async closeAllModals() {
    const top = await this.modalController.getTop();

    if (top) {
      await this.modalController.dismiss(null, null, top.id);
      await this.closeAllModals();
    }
  }
}
